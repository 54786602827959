import React, { useRef } from 'react';
import { h, render } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import BlogEntry from './blog-entry';
import { Component } from 'preact';

/**
 *
 * @param root0
 * @param root0.player
 */
export default function LiveBlogEntries( { liveBlogData, blogPostsToDisplay, blogPosts, keyEventPostData } ) {
    const pinnedPost = blogPosts.find( entry => entry?.subtitle?.includes('pinned') );

    return (
        <div className="live-blog__entries-container">
            <BlogEntry blogEntryData={ pinnedPost } isPinned={ true } />

            <BlogEntry blogEntryData={ keyEventPostData } />

            {
                keyEventPostData || pinnedPost ? (
                    <div className="live-blog__key-events-divider">
                        <span>Live updates continue below</span>
                    </div>
                ) : null
            }
        
            {
                blogPostsToDisplay.map( ( liveBlogEntry, index ) => <BlogEntry key={ index} blogEntryData={ liveBlogEntry } /> )
            }
        </div>
    );
}

LiveBlogEntries.propTypes = {
    liveBlogData: PropTypes.object.isRequired,
    blogPostsToDisplay: PropTypes.array.isRequired,
    blogPosts: PropTypes.array.isRequired,
    keyEventPostData: PropTypes.object.isRequired
};