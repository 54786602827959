import React, { useRef } from 'react';
import { h, render } from 'preact';
import { useCallback, useEffect, useState, useMemo } from 'preact/hooks';
import PropTypes from 'prop-types';
import SVGIcon from 'common/components/svg-icon.jsx';

/**
 *
 * @param root0
 * @param root0.player
 * @param root0.keyEvent
 * @param root0.liveBlogData
 */
export default function DayPicker( { liveBlogData, hasMultipleDays, blogDaysArray, blogId, setBlogId, resetBlog } ) {
    const [ currentDay, setCurrentDay ] = useState( 'Sunday' );
    const [ dropdownOpen, setDropdownOpen ] = useState( false );

    useEffect( () => {
        const currentDay = blogDaysArray.find( (blogDay) => blogDay.id === blogId );

        setCurrentDay( currentDay.day );
    }, [] )
    
    // const [ dropdownOptions, setDropdownOptions ] = useState( [ 'Friday', 'Saturday', 'Sunday' ])
   
    const onDrodpownClick = useCallback( ( clickedBlogId, day ) => {
        setCurrentDay( day );
        resetBlog();
        setBlogId( clickedBlogId )
    }, [resetBlog, setBlogId])

    return (
        hasMultipleDays ? (
            <div className={ `live-blog__day-picker ${ dropdownOpen ? 'is-open' : '' }` } onClick={ () => setDropdownOpen( !dropdownOpen ) }>
                <span className="live-blog__day-picker-label">Day</span>
                <div className="live-blog__day-picker-current current">{ currentDay }</div>
                <ul className="live-blog__day-picker-options" role="listbox">
                    {
                        blogDaysArray.map( ( blogDay, index ) => 
                            <li key={ index } onClick={ () => onDrodpownClick( blogDay.id, blogDay.day ) } tabIndex={ index } role="option">{ blogDay.day }</li>
                        )
                    }
                </ul>
            </div>
        ) : null
    );
}

DayPicker.propTypes = {
    liveBlogData: PropTypes.object.isRequired,
    blogDaysArray: PropTypes.array.isRequired
};